<template>
  <component :is="quotationData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="quotationData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات عروض الأسعار
      </h4>
      <div class="alert-body">
        لم يتم العثور على عروض الأسعار مع معرف عروض الأسعار هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'quotations-list'}"
        >
          قائمة عروض الأسعار
        </b-link>
        عروض الأسعار الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="quotationData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('quotation_edit') }}</span>
        </template>
        <quotation-edit-tab-information
          :quotation-data="quotationData"
          class="mt-2 pt-75"
          @reset-quotation-data="resetQuotationData"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import quotationStore from '../quotationStore';
import QuotationEditTabInformation from './QuotationEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    QuotationEditTabInformation,
  },
  setup() {
    const quotationData = ref(null);
    const oldQuotationData = ref(null);

    const QUOTATION_APP_STORE_MODULE_NAME = 'app-quotation';
    const resetQuotationData = () => {
      if (oldQuotationData.value !== null) {
        quotationData.value = JSON.parse(JSON.stringify(oldQuotationData.value));
      }
    };
    // Register module
    if (!store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) store.registerModule(QUOTATION_APP_STORE_MODULE_NAME, quotationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUOTATION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUOTATION_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-quotation/fetchQuotation', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: quotation } = response.data;
        quotationData.value = quotation;
        oldQuotationData.value = JSON.parse(JSON.stringify(quotation));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          quotationData.value = undefined;
        }
      });

    return {
      quotationData,
      resetQuotationData,
    };
  },
};
</script>

<style>

</style>
